import React, { useContext } from 'react';
import { ThemeContext } from '../utils';
import { Link } from 'gatsby';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/blogPost.css';
import Severity from './severity';

const InnerPost = ({ post, severity, previous, next }) => {
  const [theme] = useContext(ThemeContext);
  console.log({ post, severity });
  return (
    <div className={`duration-300 transition-colors p-3 ${theme.text}`}>
      <article className={`mx-auto max-w-6xl ${theme.name}`}>
        <header className="mb-3">
          <small className="text-gray-400">{post.frontmatter.date}</small>
          <h1 className="text-4xl mt-0 font-black">{post.frontmatter.title}</h1>
          <div className="mt-1">
            <Severity value={post.frontmatter.severity} />
          </div>
        </header>
        <section
          className={`duration-300 transition-colors tracking-wide ${theme.textExtraLight}`}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr className="mb-3" />
      </article>

      <nav className="mx-auto max-w-6xl">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link className="no-underline flex items-center" to={`/advisories${previous.fields.slug}`} rel="prev">
                <FontAwesomeIcon className="text-xs mr-2" icon={faArrowLeft} /> {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link className="no-underline flex items-center" to={`/advisories${next.fields.slug}`} rel="next">
                {next.frontmatter.title} <FontAwesomeIcon className="text-xs ml-2" icon={faArrowRight} />
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default InnerPost;
